<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>劳务付款</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <el-button v-if="detail.sign" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i :class="{red:detail.revoke_type_state === 1 || detail.shenpi_num > 1     }">(<i
                                v-if="detail.revoke_type_state === 1">撤销后，</i>第{{ detail.shenpi_num }}次提交)</i></div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">付款类型：</span>
                    <span class="approval-detail-content">{{ detail.payment_type === 1 ? '会议基金' : '专项基金' }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">委托项目：</span>
                    <span class="approval-detail-content">{{ detail.pay_item }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">付款金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.pay_amount).toFixed(6))}}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">附件：</span>
                    <div class="approval-detail-file">
                        <a v-for="(file, index) in detail.files" :key="index" :href="file.filePath | filePath"
                           target="_blank">{{ file.fileName }}</a>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">二维码：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.qr_code" style="width: 150px; height: 150px"></el-image>
                    </div>
                </div>
            </div>
            <div v-if="detail.sign" class="approval-detail-container">
                <div class="approval-list-tip">签字信息</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">签名：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.sign" style="width: 550px; height: auto"></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>
                <div class="approval-list">
                    <!--          付款：审核状态 0已提交申请，等待签字  1审批完成，等待发起人确认  2已签字，选择付款交接文件  3已驳回  4二次提交，审批中  5撤销  6已完成-->
                    <div class="name-container active">
                        <span>{{ detail.true_name | sub_name }}<i class="el-icon-success"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起项目付款确认单签字申请</div>
                        </div>
                    </div>

                    <div class="name-container active">
                        <span>签字<i :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">申请人</div>
                                <div v-if="detail.allSp[0].sp_time " class="info-time">
                                    {{ detail.allSp[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.sign ? "申请人已签字" : "签字中" }}
                            </div>
                        </div>
                    </div>

                    <div v-if="detail.sign" class="name-container active">

            <span>
              {{ detail.true_name | sub_name }}
              <i
                  :class="
                  detail.fukuan_jiaojie.length > 0
                    ? 'el-icon-success'
                    : 'el-icon-remove'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div v-if="detail.allSp[1].sp_time " class="info-time">
                                    {{ detail.allSp[1].sp_time | formatTime }}
                                </div>
                                <!--<div class="info-time">{{ detail.add_time | formatTime }}</div>-->
                            </div>
                            <div v-if="detail.fukuan_jiaojie.length > 0" class="info-desc">
                                <div v-for="(item,index) in detail.subsidiary.slice(0,1)" :key="index">
                                    {{ detail.true_name }}付款交接文件：{{ item.fukuan_jiaojie.split(',') | fukuan_jiaojie
                                    }} <br>发票总金额：{{ parseFloat(Number(detail.all_amount).toFixed(6)) }}万元<br>
                                    发票金额：{{ parseFloat(Number(item.all_amount).toFixed(6)) }}万元 备注：{{ item.notes }}
                                </div>
                            </div>
                            <div v-else class="info-desc">
                                <div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>
                                <div v-if="member_type == 1">
                                    <!--<div class="info-desc">-->
                                    <!--<div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>-->
                                    <!--<div >-->
                                    <div class="input-item">
                                        <div class="input-item-title">付款交接文件</div>
                                        <div class="flex-center">
                                            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
                                                         @change="handleCheckAllChange">全选
                                            </el-checkbox>
                                            <el-checkbox-group v-model="fukuan_jiaojie" class="margin-left"
                                                               @change="handleCheckedChange">
                                                <el-checkbox label="1">下游专票</el-checkbox>
                                                <el-checkbox label="2">下游协议</el-checkbox>
                                                <el-checkbox label="3">项目明细</el-checkbox>
                                                <el-checkbox label="4">支持性文件</el-checkbox>
                                                <el-checkbox label="5">支出凭单</el-checkbox>
                                            </el-checkbox-group>
                                            <div class="margin-left">发票金额：</div>
                                            <el-input v-model="all_amount" placeholder="请填写发票总金额" style="width: 300px"
                                                      type="number" @mousewheel.native.prevent></el-input>
                                            <div>万元</div>
                                        </div>
                                        <div class="flex-center">
                                            <div class="margin-left flex-shrink">备注：</div>
                                            <el-input v-model="notes" placeholder="请填写备注"></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.fukuan_jiaojie.length > 0">
                        <div v-for="(item,index) in detail.shenpi.slice(0,3)"
                             :key="item.id">
                            <div
                                v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                class="name-container active"
                            >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0 || item.state === 3
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name
                                            }}<i v-if="item.state != 0"
                                        >({{ item.state | sp_state }})</i
                                        >
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state === 0" class="info-desc">
                                        {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                        }}{{ iself(item) ? "）" : "" }}
                                    </div>
                                    <div v-if="item.state !== 0" class="info-desc">
                                        {{ item.sp_remark }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-list-container list-bottom">
                <div class="approval-list-tip list-bottom">审批流程</div>

                <div class="approval-list">

                    <div
                        v-if="detail.shenpi[2].state == 1"
                    >
                        <div
                            v-for="item in detail.shenpi.slice(3,4)"
                            :key="item.id"
                            class="name-container active"
                        >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state === 0
                    ? 'el-icon-remove'
                    : item.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name
                                        }}
                                    </div>
                                    <div v-if="item.sp_time" class="info-time">
                                        {{ item.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div v-if="item.state == 1" class="info-desc">
                                    金额：{{ parseFloat(Number(detail.payment_amount).toFixed(6)) }}万元
                                    时间：{{ detail.payment_date }}
                                </div>
                                <div v-else class="info-desc">
                                    <!--等待财务对接人确认-->
                                    付款中
                                </div>
                            </div>
                        </div>

                    </div>
                    <div
                        v-if="detail.shenpi[3].state == 1"
                    >
                        <div
                            v-for="(item,index) in detail.shenpi.slice(4)"
                            :key="item.id"
                        >
                            <div
                                v-if="detail.shenpi[3+index].state == 1 && index%2==0"
                                class="name-container active"
                            >
            <span>
              {{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state == 1 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name }}
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="detail.fukuan_state" class="info-desc">
                                        {{ item.state == 1 ? "确认知晓" : "确认中" }}
                                    </div>
                                    <div v-else>
                                        <div v-if="item.state === 1" class="info-desc">
                                            发票总金额：{{ parseFloat(Number(detail.all_amount).toFixed(6)) }}万元<br>
                                            付款交接文件：{{ item.fukuan_jiaojie.split(',') | fukuan_jiaojie
                                            }} <br>发票金额：{{ parseFloat(Number(item.all_amount).toFixed(6)) }}万元
                                            备注：{{ item.notes }}
                                        </div>
                                        <div v-else class="info-desc">
                                            <div v-if="member_type != 1">{{ item.true_name }}付款交接文件补全中</div>
                                            <div v-if="member_type == 1">
                                                <!--<div class="info-desc">-->
                                                <!--<div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>-->
                                                <!--<div >-->
                                                <div class="input-item">
                                                    <div class="input-item-title">付款交接文件</div>
                                                    <div class="flex-center">
                                                        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
                                                                     @change="handleCheckBCAllChange">全选
                                                        </el-checkbox>
                                                        <el-checkbox-group v-model="fukuan_jiaojie" class="margin-left"
                                                                           @change="handleCheckedChange">
                                                            <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('1') !== -1 "
                                                                         label="1">
                                                                下游专票
                                                            </el-checkbox>
                                                            <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('2') !== -1 "
                                                                         label="2">
                                                                下游协议
                                                            </el-checkbox>
                                                            <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('3') !== -1 "
                                                                         label="3">
                                                                项目明细
                                                            </el-checkbox>
                                                            <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('4') !== -1 "
                                                                         label="4">
                                                                支持性文件
                                                            </el-checkbox>
                                                            <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('5') !== -1 "
                                                                         label="5">
                                                                支出凭单
                                                            </el-checkbox>
                                                        </el-checkbox-group>
                                                        <div class="margin-left">发票金额：</div>
                                                        <el-input v-model="all_amount" placeholder="请填写发票总金额"
                                                                  style="width: 300px"
                                                                  type="number"
                                                                  @mousewheel.native.prevent></el-input>
                                                        <div>万元</div>
                                                    </div>
                                                    <div class="flex-center">
                                                        <div class="margin-left flex-shrink">备注：</div>
                                                        <el-input v-model="notes" placeholder="请填写备注"></el-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="detail.shenpi[3+index].state == 1 && index%2==1"
                                class="name-container active"
                            >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state === 0
                    ? 'el-icon-remove'
                    : item.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name
                                            }}
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="detail.state == 7" class="info-desc">
                                        {{ item.state === 1 ? '付款资料补充已确认' : '付款资料补充存档中' }}
                                    </div>
                                    <div v-else class="info-desc">
                                        <!--等待财务对接人确认-->
                                        {{ detail.shenpi.length == (3 + index) ? '付款资料补充已存档' : '付款资料补充已确认' }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name"
                                >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 &&  detail.state == 0"
                    class="approval-btn-left"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <el-button
                    v-if="(member_type == 1 && detail.state == 2) ||
                     (detail.state == 0 && member_type == 0 && detail.confirm === 1) ||
                     (member_type == 0 && isDJApprovaler && !isCDApprovaler) ||
                      (member_type == 1 && detail.state == 1 && !detail.shenpi.some(item => item.fukuan_jiaojie)) ||
                     (member_type == 0 && isApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button
                    v-if="member_type == 1 && detail.state == 2"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="subFile()">
                    提交
                </el-button>
                <el-button
                    v-if="member_type == 1 && detail.state == 1"
                    :disabled="requestState"
                    :loading="requestState"
                    class="approval-btn-right" @click="setConfirm()">
                    确认
                </el-button>
                <router-link :to="'/home/payLw?id=' + pid"  v-if="member_type == 1 && detail.state == 3">
                    <el-button class="approval-btn-left">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler "
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
                <el-button v-if="member_type == 0 && isDJApprovaler && !isCDApprovaler"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="showConfirmDialog()">
                    确认
                </el-button>

                <el-button
                    v-if=" member_type == 0 && isCDApprovaler"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-left"
                    @click="revertStep()">
                    重新提交
                </el-button>
                <el-button
                    v-if="member_type == 0 && isCDApprovaler"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="setSureFlow()">
                    {{ parseFloat(Number(detail.pay_amount).toFixed(6)) > parseFloat(Number(detail.all_amount).toFixed(6)) ? '确认' : '存档'}}
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/payLwDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editConfirmVisible"
            center
            width="30%"
        >
            <span slot="title" class="dialog-title">确认</span>
            <div class="dialog-container">
                <div class="dialog-confirm-container flex-center">
                    <div class="dialog-tip">付款金额：</div>
                    <el-input
                        v-model="payment_amount"
                        class="approval-detail-content"
                        placeholder="请输入金额"
                    ></el-input>
                    <div class="dialog-tip">万元</div>
                </div>
                <div class="dialog-confirm-container flex-center">
                    <div class="dialog-tip">付款日期：</div>
                    <el-date-picker
                        v-model="payment_date"
                        placeholder="选择日期"
                        type="datetime"
                    >
                    </el-date-picker>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toConfirm()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{item.create_time | formatTime}}驳回了审批，驳回原因为：{{item.message}}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">驳回<br>记录</div>
        </el-tooltip>
        <labour-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></labour-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import ElImage from "../../../node_modules/element-ui/packages/image/src/main.vue";
import config from "../../config/index";
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import LabourPrint from "./print/labour";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo,
        LabourPrint,
        ElImage,
        ElButton,
    },
    name: "payDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            fukuan_jiaojie: [],
            fukuan_jiaojie_rule: false,
            isApprovaler: false,
            isDJApprovaler: false,
            isCDApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            editConfirmVisible: false,
            checkAll: false,
            isIndeterminate: false,
            requestState: false,
            state: -1,
            detail: '',
            remark: "",
            all_amount: "",
            notes: "",
            payment_amount: "",
            payment_date: '',
            printDialogVisible:false
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
//                this.remark = "同意";
        },
        showConfirmDialog() {
            this.editConfirmVisible = true;
            this.payment_date = new Date()
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.editConfirmVisible = false;
            this.state = -1;
        },
        toConfirm() {
            if (!this.payment_amount) {
                this.$message.error("请输入金额");
                return;
            }
            if (parseFloat(Number(this.payment_amount).toFixed(6)) !== parseFloat(Number(this.detail.pay_amount).toFixed(6))) {
                this.$message.error("输入的付款金额与申请的金额不一致，无法确认");
                return;
            }
            this.$api.apiContent
                .upLwFile({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    payment_amount: this.payment_amount,
                    payment_date: this.$tools.ZHDate(new Date(this.payment_date), 6),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            payment_amount: this.payment_amount,
                            payment_date: this.$tools.ZHDate(new Date(this.payment_date), 6),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then(() => {
                    this.state = 1
                    this.remark = '同意'
                    this.toApproval()
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return  this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state:2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 22, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state:2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        setConfirm() {
            if (this.fukuan_jiaojie.length !== 5) {
                this.$message.error("请补全付款交接文件");
                return;
            }
            if (this.fukuan_jiaojie.length !== 5 && !this.all_amount) {
                this.$message.error("请填写发票总金额");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .setLwConfirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    all_amount: this.all_amount,
                    notes: this.notes,
                    fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            all_amount: this.all_amount,
                            notes: this.notes,
                            fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        setSureFlow() {
            this.requestState = true
            this.$api.apiContent
                .setLwSureFlow({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        revertStep() {
            this.requestState = true
            this.$api.apiContent
                .revertLwStep({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        subFile() {
            if (this.fukuan_jiaojie.length === 0) {
                this.$message.error("请选择付款交接文件");
                return;
            }
            if (!this.all_amount) {
                this.$message.error("请填写发票总金额");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .setLwPayment({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    all_amount: this.all_amount,
                    notes: this.notes,
                    fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            all_amount: this.all_amount,
                            notes: this.notes,
                            fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getLwFukuan({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.allSp = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.slice(2)
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    res.data.fukuan_jiaojie.sort()
                    res.data.qr_code = config.urlImgStr + res.data.qr_code;
                    res.data.sign = res.data.sign ? config.urlImgStr + res.data.sign : "";
//                        this.all_amount = parseFloat(Number(res.data.all_amount).toFixed(6))
                    this.fukuan_jiaojie = res.data.fukuan_jiaojie
//                        this.notes = res.data.notes
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.shenpi_rejectedVisible = false;
            this.editConfirmVisible = false;
            this.isApprovaler = false;
            this.isDJApprovaler = false;
            this.editVisible = false;
            this.isCDApprovaler = false
            this.isCDApprovaler = this.detail.state === 7 && this.detail.shenpi.find(item => item.state === 0).user_id === this.$store.state.user.mid;
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 4;
                    } else if (index === 3) {
                        this.isDJApprovaler = this.detail.shenpi[3].state === 0 && this.detail.shenpi[2].state === 1;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = this.detail.state === 4;
                        }
                    }
                }
            });
            let that = this
            setTimeout(function () {
                that.requestState = false
            }, 2000)

        },
        handleCheckAllChange(val) {
            if (val && this.fukuan_jiaojie.indexOf('2') === -1 && this.detail.contract !== 1) {
                this.$confirm('协议未回，是否勾选?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.fukuan_jiaojie = val ? ['1', '2', '3', '4', '5'] : [];
                    this.isIndeterminate = false;
                });
            } else {
                this.fukuan_jiaojie = val ? ['1', '2', '3', '4', '5'] : [];
                this.isIndeterminate = false;
            }

        },
        handleCheckBCAllChange(val) {
            if (val && this.fukuan_jiaojie.indexOf('2') === -1 && this.detail.contract !== 1) {
                this.$confirm('协议未回，是否勾选?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.fukuan_jiaojie = val ? ['1', '2', '3', '4', '5'] : this.detail.fukuan_jiaojie;
                    this.isIndeterminate = false;
                });
            } else {
                this.fukuan_jiaojie = val ? ['1', '2', '3', '4', '5'] : this.detail.fukuan_jiaojie;
                this.isIndeterminate = false;
            }

        },
        handleCheckedChange(value) {
            if (this.fukuan_jiaojie_rule && Number(value[value.length - 1]) === 2 && this.detail.contract !== 1) {
                this.fukuan_jiaojie = value.slice(0, value.length - 1)
                this.$confirm('协议未回，是否勾选?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.fukuan_jiaojie.push('2')
                    let checkedCount = this.fukuan_jiaojie.length;
                    this.checkAll = checkedCount === 5;
                    this.isIndeterminate = checkedCount > 0 && checkedCount < 5;
                });
            }
            let checkedCount = this.fukuan_jiaojie.length;
            this.checkAll = checkedCount === 5;
            this.isIndeterminate = checkedCount > 0 && checkedCount < 5;
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        fukuan_jiaojie(type) {
            let arr = ["下游专票", "下游协议", "项目明细", "支持性文件", "支出凭单"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        jiesuan_jiaojie(type) {
            let arr = ["会议结算单", "结算单文件"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "发起人确认中";
                case 2:
                    return "已签字，发起人选择付款交接文件中";
                case 3:
                    return "已驳回";
                case 4:
                    return "审批中";
                case 5:
                    return "已撤销";
                case 7:
                    return "付款资料补充存档中";
                case 6:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
        fukuan_jiaojie(old, value) {
            this.fukuan_jiaojie_rule = old.length > value.length
        }
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-confirm-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #333333;

        .dialog-tip {
            width: 120px;
            flex-shrink: 0;
        }

        .el-input,
        .el-date-editor--datetime,
        .el-date-editor,
        .el-radio-group,
        .el-textarea,
        .el-textarea__inner {
            flex-grow: 1;
            margin: 0 10px;
            font-size: 16px;
        }
    }

    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;
        .approval-detail-file {
            flex: 7;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;

            a {
                font-size: 18px;
                cursor: pointer;
                margin-bottom: 8px;
                display: block;
                color: #3b77e7;

                &:hover {
                    font-size: 18px;
                }
            }
        }
        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px 0;

            &.list-bottom {
                padding: 0 80px;
            }

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;

                &.list-bottom {
                    color: #ffffff;
                }
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;

                        .red {
                            color: red;
                        }
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
